import { cloneDeep } from "lodash";
import {
  getAllowedTags,
  getAllowedSelfClosingTags,
  getAllowedDataSizeValues,
  getAllowedDataIntegerSizeValues,
  getAllowedDataTextAsImageValues,
  getAllowedStyleFontSizeValues,
  getAllowedStyleTextAlignValues,
  getAllowedImgStyleValues,
  getAllowedBlockquoteStyleValues,
  getAllowedPStyleValues,
  getAllowedOrderedListStyleValues,
  getAllowedUnorderedListStyleValues,
} from "src/rich-text/bandicoot-html-sanitizer.config";
import { imgSrcUrlHasError, aHrefUrlHasError } from "src/rich-text/bandicoot-url-validator";

const allowedAttributesByTag = {
  a: [
    "href", // anchors can only have a limited href attribute (NO styles). See allowedSchemesByTag, transformTags, and exclusiveFilter.
  ],
  img: [
    "src", // imgs can only have a limited src attribute. See allowedSchemesByTag and exclusiveFilter.
    "style", // imgs can only have limited styles. See allowedStyles.
    {
      name: "data-size",
      values: getAllowedDataSizeValues(),
    },
  ],
  font: [
    "style", // fonts can only have limited styles.  See allowedStyles.
    {
      name: "data-integer-size",
      values: getAllowedDataIntegerSizeValues(),
    },
  ],
  div: [
    "style", // divs can only have limited styles. See allowedStyles.
  ],
  blockquote: [
    "style", // blockquotes can only have limited styles.  See allowedStyles.
  ],
  span: [
    "style", // spans can only have limited styles. See allowedStyles.
    {
      name: "data-text-as-image",
      values: getAllowedDataTextAsImageValues(),
    },
  ],
  p: [
    "style", // p tags can only have limited styles.  See allowedStyles.
  ],
  ol: [
    "style", // Ordered lists can only have limited styles. See allowedStyles.
  ],
  ul: [
    "style", // Unordered lists can only have limited styles. See allowedStyles.
  ],
};

const allowedClassesByTag = {
  span: ["cp-merge-tag"],
  div: ["signature"],
};

const allowedStylesByTag = {
  img: {
    "max-width": mapStringArrayToExactRegexArray(getAllowedImgStyleValues()["max-width"]),
    "max-height": mapStringArrayToExactRegexArray(getAllowedImgStyleValues()["max-height"]),
  },
  font: {
    "font-size": mapStringArrayToExactRegexArray(getAllowedStyleFontSizeValues()),
  },
  div: {
    "text-align": mapStringArrayToExactRegexArray(getAllowedStyleTextAlignValues()),
  },
  blockquote: {
    margin: mapStringArrayToExactRegexArray(getAllowedBlockquoteStyleValues().margin),
    border: mapStringArrayToExactRegexArray(getAllowedBlockquoteStyleValues().border),
    padding: mapStringArrayToExactRegexArray(getAllowedBlockquoteStyleValues().padding),
  },
  p: {
    margin: mapStringArrayToExactRegexArray(getAllowedPStyleValues().margin),
  },
  ol: {
    padding: mapStringArrayToExactRegexArray(getAllowedOrderedListStyleValues().padding),
  },
  ul: {
    padding: mapStringArrayToExactRegexArray(getAllowedUnorderedListStyleValues().padding),
  },
  span: {
    "font-size": mapStringArrayToExactRegexArray(getAllowedStyleFontSizeValues()),
  },
};

function mapStringArrayToExactRegexArray(stringArray) {
  return stringArray.map((str) => {
    return new RegExp(`^${str}$`);
  });
}

const allowedSchemesByTag = {
  a: ["https", "http", "mailto"],
  img: ["https"],
};

const allowedSchemesAppliedToAttributes = ["href", "src"];

// DO NOT ALLOW RELATIVE PROTOCOLS
const allowProtocolRelative = false;

// The bandicoot sanitizeHtmlConfig is meant to be as RESTRICTIVE as possible!  DO NOT MODIFY IT WITHOUT CARE!
const sanitizeHtmlConfig = {
  allowedTags: getAllowedTags(),
  selfClosing: getAllowedSelfClosingTags(),
  allowedAttributes: allowedAttributesByTag,
  allowedClasses: allowedClassesByTag,
  allowedStyles: allowedStylesByTag,
  allowedSchemesByTag,
  allowedSchemesAppliedToAttributes,
  allowProtocolRelative,
  transformTags: {},
  exclusiveFilter: function (frame) {
    let exclude = true;

    switch (frame.tag) {
      case "img":
        exclude = imgSrcUrlHasError(frame.attribs.src);
        break;
      case "a":
        exclude = aHrefUrlHasError(frame.attribs.href);
        break;
      default:
        exclude = false;
        break;
    }

    return exclude;
  },
};

export default function getSanitizeHtmlConfig() {
  return cloneDeep(sanitizeHtmlConfig);
}
