import React from "react";
import PropTypes from "prop-types";
import { Observable, from } from "rxjs";
import { pluck } from "rxjs/operators";
import { createRoot } from "react-dom/client";
import Cancelable from "react-disposable-decorator";
import { CpModal } from "canopy-styleguide!sofe";
import { handleError } from "src/error";

/* Returns an rxjs6 observable where if you dispose the subscription, the modal is
 * unmounted from the DOM. It onNexts one value, the account that was either created
 * or modified (to syncEmail or syncCalendar).
 */
export function connectAccountModal(props) {
  return new Observable((observer) => {
    const modalContainer = document.createElement("div");
    document.body.appendChild(modalContainer);
    const root = createRoot(modalContainer);

    const handleOnNext = (account) => {
      observer.next(account);
    };

    const handleOnError = (error) => {
      observer.error(error);
    };

    const handleOnCompleted = () => {
      observer.complete();
    };

    root.render(
      <ExternallyExportedConnectAccount
        {...props}
        onNext={handleOnNext}
        onError={handleOnError}
        onCompleted={handleOnCompleted}
      />
    );

    // Cleanup function when the observable is unsubscribed
    return () => {
      root.unmount();
      document.body.removeChild(modalContainer);
    };
  });
}

/* This component is the external api used by calendar-ui and maybe other sofe services
 * to connect an email/calendar account. We do not expose the entirety of the AddWizard
 * implementation details to the outside services, but rather a more simplified API.
 *
 */
@Cancelable
export default class ExternallyExportedConnectAccount extends React.Component {
  static propTypes = {
    // Required
    callerContext: PropTypes.oneOf(["prompt-for-email", "prompt-for-calendar", "prompt-for-new"]).isRequired,
    accounts: PropTypes.array.isRequired,

    // Provided by the observable returned in connectAccountModal
    onNext: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onCompleted: PropTypes.func.isRequired,

    // Optional
    skipClientImport: PropTypes.bool,
  };

  state = {
    accounts: this.props.accounts,
    AddWizard: null,
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      from(import(/* webpackChunkName: "add-wizard" */ "./add-wizard.component.js"))
        .pipe(pluck("default"))
        .subscribe((AddWizard) => this.setState({ AddWizard }), handleError)
    );
  }

  render() {
    const { AddWizard } = this.state;
    if (!AddWizard) return null;

    return (
      <CpModal show={true} onClose={this.props.onCompleted}>
        <AddWizard
          close={this.props.onCompleted}
          emailAccountId={null}
          step={1}
          customHeader={null}
          skipClientImport={this.props.skipClientImport || false}
          accounts={this.state.accounts}
          newAccountConnected={this.newAccountConnected}
          existingAccountUpdated={this.existingAccountUpdated}
          callerContext={this.props.callerContext}
          forwardToSettings={false}
        />
      </CpModal>
    );
  }

  newAccountConnected = (newAccount) => {
    this.setState((prevState) => ({
      accounts: [...prevState.accounts, newAccount],
    }));

    this.props.onNext(newAccount);
  };

  existingAccountUpdated = (updatedAccount) => {
    this.setState((prevState) => ({
      accounts: prevState.accounts.map((account) => (account.id === updatedAccount.id ? updatedAccount : account)),
    }));
    this.props.onNext(updatedAccount);
  };
}
