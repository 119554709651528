export function getCommonWebProtocols() {
  return ["http", "https", "ftp", "data", "file", "irc"];
}

export function getCommonEmailProtocols() {
  return ["mailto"];
}

export function getCommonUrlProtocols() {
  return [...getCommonWebProtocols(), ...getCommonEmailProtocols()];
}

export function getLowerCaseLetters() {
  return [..."abcdefghijklmnopqrstuvwxyz"];
}

export function getUpperCaseLetters() {
  return getLowerCaseLetters().map((lowerCaseCharacter) => lowerCaseCharacter.toUpperCase());
}

export function getLetters() {
  return [...getLowerCaseLetters(), ...getUpperCaseLetters()];
}

export function getNumbers() {
  return [..."0123456789"];
}

export function getCommonSymbols() {
  return [...`~\`!@#$%^&*()_-+={[}]|\\:;"'<,>.?/`];
}

export function getCommonEscapeCharacters() {
  return [...`\n\r\v\t\b\f`];
}

export function getCommonUrlCharacters() {
  return [...getLetters(), ...getNumbers(), ...getCommonSymbols(), ...getCommonEscapeCharacters()];
}
